import { PayloadAction, Slice, createSlice } from "@reduxjs/toolkit";
import {
  AccountingFirmBillingInformationType,
  AccountingFirmConnectorType,
  AccountingFirmConnectorTypeType,
  AccountingFirmPreferenceType,
  AccountingFirmType,
  CompanyType,
  UserTeamType,
  UserType,
} from "utilities/redux/types";

type AccountingFirmDataType = {
  accountingFirm: AccountingFirmType;
  accountingFirmBillingInformations: AccountingFirmBillingInformationType;
  usersCount: number | undefined;
  entriesImportsCount: number | undefined;
  companiesCount: number | undefined;
  questionsCount: number | undefined;
  ////
  accountingFirCompanies: CompanyType[];
  ////
  collaborators: UserType[];
  collaboratorSelected: UserType;
  ///
  userTeams: UserTeamType[];
  userTeamSelected: UserTeamType;
  ///
  accountingFirmPreferences: AccountingFirmPreferenceType;
  ///
  connectors: AccountingFirmConnectorType[];
  connnectorAvailables: AccountingFirmConnectorTypeType[];
  connnectorAvailableSelected: AccountingFirmConnectorTypeType;
  connectorSelected: AccountingFirmConnectorType;
  picture: any;
  mustEnterPM: boolean;
  mustEnterPMModal: boolean;
};

const initialState: AccountingFirmDataType = {
  accountingFirmBillingInformations: null,
  accountingFirm: null,
  usersCount: undefined,
  entriesImportsCount: undefined,
  companiesCount: undefined,
  questionsCount: undefined,
  /// all companies from accountingFirm
  accountingFirCompanies: [],
  //
  collaborators: null,
  collaboratorSelected: null,
  //
  userTeams: [],
  userTeamSelected: null,
  //
  accountingFirmPreferences: null,
  //
  connnectorAvailables: [],
  connectors: [],
  connectorSelected: null,
  connnectorAvailableSelected: null,
  picture: null,
  // alert no payment
  mustEnterPM: false,
  mustEnterPMModal: false,
};

export const AccountingFirmSlice: Slice<AccountingFirmDataType> = createSlice({
  name: "accountingFirmData",
  initialState,
  reducers: {
    setMustEnterPM: (state, action: PayloadAction<boolean>) => {
      state.mustEnterPM = action.payload;
    },
    setMustEnterPMModal: (state, action: PayloadAction<boolean>) => {
      state.mustEnterPMModal = action.payload;
    },
    setAccountingFirm: (state, action: PayloadAction<AccountingFirmType>) => {
      state.accountingFirm = action.payload;
      state.connectors = state.accountingFirm?.accountingFirmConnectors ?? [];
    },
    setAccountingFirCompanies: (state, action: PayloadAction<CompanyType[]>) => {
      state.accountingFirCompanies = action.payload;
    },

    setAccountingFirmBillingInformations: (state, action: PayloadAction<AccountingFirmBillingInformationType>) => {
      state.accountingFirmBillingInformations = action.payload;
    },
    setUsersCount: (state, action: PayloadAction<number>) => {
      state.usersCount = action.payload;
    },
    setEntriesImportsCount: (state, action: PayloadAction<number>) => {
      state.entriesImportsCount = action.payload;
    },
    setCompaniesCount: (state, action: PayloadAction<number>) => {
      state.companiesCount = action.payload;
    },
    setQuestionsCount: (state, action: PayloadAction<number>) => {
      state.questionsCount = action.payload;
    },
    setConnectors: (state, action: PayloadAction<AccountingFirmConnectorType[]>) => {
      state.connectors = action.payload;

      if (state.connectorSelected?.accountingFirmConnectorType) {
        const updatedConnectorSelected = action.payload.find(
          (c) => c.accountingFirmConnectorType.value === state.connectorSelected.accountingFirmConnectorType.value,
        );

        state.connectorSelected = updatedConnectorSelected;
      }
    },

    setConnnectorAvailables: (state, action: PayloadAction<AccountingFirmConnectorTypeType[]>) => {
      state.connnectorAvailables = action.payload;
    },
    setConnectorSelected: (state, action: PayloadAction<AccountingFirmConnectorType>) => {
      state.connectorSelected = action.payload;
    },

    updateConnector: (state, action: PayloadAction<AccountingFirmConnectorType>) => {
      const index = state.connectors?.findIndex((c) => c.id === action.payload?.id);

      if (index !== -1) {
        state.connectors = [...state.connectors.slice(0, index), action.payload, ...state.connectors.slice(index + 1)];
      }

      if (action.payload?.id === state.connectorSelected?.id) {
        state.connectorSelected = action?.payload;
      }
    },
    updateDefaultConnector: (state, action: PayloadAction<AccountingFirmConnectorType>) => {
      if (state.accountingFirm) {
        let copy = state.accountingFirm;
        copy.preference.defaultConnector = action.payload;
        state.accountingFirm = copy;
      }
    },

    // USERS
    setCollaborators: (state, action: PayloadAction<UserType[]>) => {
      state.collaborators = action.payload;
    },
    setCollaboratorSelected: (state, action: PayloadAction<UserType>) => {
      state.collaboratorSelected = action.payload;
    },
    /// PREFS
    setAccountingFirmPreferences: (state, action: PayloadAction<AccountingFirmPreferenceType>) => {
      state.accountingFirmPreferences = action.payload;
    },
    updateAccountingFirmPreferences: (state, action: PayloadAction<any>) => {
      let copy = JSON.parse(JSON.stringify(state.accountingFirmPreferences));

      copy = { ...copy, ...action.payload };
      state.accountingFirmPreferences = copy;
    },

    // TEAMS
    setUserTeams: (state, action: PayloadAction<UserTeamType[]>) => {
      state.userTeams = action.payload;
    },
    setUserTeamSelected: (state, action: PayloadAction<UserTeamType>) => {
      state.userTeamSelected = action.payload;
    },
    updateUserTeamSelected: (state, action: PayloadAction<any>) => {
      /// UPDATE TEAM SELECTED
      let copy = JSON.parse(JSON.stringify(state.userTeamSelected));
      copy = { ...copy, ...JSON.parse(JSON.stringify(action.payload)), isModified: true };
      state.userTeamSelected = copy;

      /// UPDATE TEAMS
      let index = state.userTeams?.findIndex((item) => item.slug == state.userTeamSelected.slug);
      let copyTeams = JSON.parse(JSON.stringify(state.userTeams));
      copyTeams[index] = copy;

      state.userTeams = copyTeams;
    },
    switchUserBetweenTeams: (state, action: PayloadAction<{ emailUser: string; slugNewTeam: string; }>) => {
      /// input -> emailUser et slug
      let copyTeams: UserTeamType[] = JSON.parse(JSON.stringify(state.userTeams));
      let copyCollabs: UserType[] = JSON.parse(JSON.stringify(state.collaborators));
      let selectedCollab: UserType = copyCollabs.find((user) => user.email == action.payload.emailUser);
      let oldTeam: UserTeamType = JSON.parse(JSON.stringify(selectedCollab?.userTeam));

      // console.log("oldTeam");
      // console.log(oldTeam);

      /// add User To new Team
      let newTeamIndex = copyTeams?.findIndex((team) => team.slug === action.payload.slugNewTeam);
      let copyNewTeamUsers = copyTeams[newTeamIndex].users;
      copyNewTeamUsers.push(selectedCollab);

      /// remove User to old teal
      let teamOldIndex = copyTeams?.findIndex((team) => team?.slug === oldTeam?.slug);
      let teamOldUsers = JSON.parse(JSON.stringify(copyTeams[teamOldIndex].users));
      teamOldUsers = teamOldUsers?.filter((user) => user?.email != action.payload.emailUser);

      //// reaffect collab
      // let collabIndex = copyCollabs.findIndex((item) => item.email == action.payload.emailUser);
      // copyCollabs[collabIndex].userTeam = copyTeams[newTeamIndex];
      // console.log("finalCollabs");
      // console.log(copyCollabs);
      // state.collaborators = copyCollabs;

      /// reaffect store
      copyTeams[newTeamIndex].users = copyNewTeamUsers;
      copyTeams[teamOldIndex].users = teamOldUsers;
      // console.log("finalTeams");
      // console.log(copyTeams);
      //
      state.userTeams = copyTeams;
    },

    ///
    updateCollaborator: (state, action: PayloadAction<UserType>) => {
      let copyCollabs = JSON.parse(JSON.stringify(state.collaborators));

      const collaboratorIndex = state?.collaborators?.findIndex((user) => user?.id === action?.payload?.id);

      if (collaboratorIndex !== -1) {
        copyCollabs[collaboratorIndex] = action.payload;
        state.collaborators = copyCollabs;
      }

      state.collaboratorSelected = action.payload;
    },

    addCollaborator: (state, action: PayloadAction<{ collaborator: UserType; }>) => {
      let collaboratorsCopy = JSON.parse(JSON.stringify(state.collaborators));

      collaboratorsCopy.push(action.payload.collaborator);

      state.collaborators = collaboratorsCopy;
    },

    deleteCollaborator: (state, action: PayloadAction<{ collaboratorId: number; }>) => {
      let collaboratorsCopy = JSON.parse(JSON.stringify(state.collaborators));

      collaboratorsCopy = collaboratorsCopy.filter(
        (collaborator: UserType) => collaborator.id !== action.payload.collaboratorId,
      );

      state.collaborators = collaboratorsCopy;
    },
    removeAffectation: (state, action: PayloadAction<{ collaboratorSelected: UserType; company: CompanyType; }>) => {
      let temp = JSON.parse(JSON.stringify(state.collaborators));
      let tempCollabSelected = JSON.parse(JSON.stringify(state.collaboratorSelected));

      /// update on selected collaborator
      let copyAffected = tempCollabSelected.companiesAffected;
      copyAffected = copyAffected.filter((company) => company.slug != action.payload.company.slug);
      tempCollabSelected.companiesAffected = copyAffected;
      state.collaboratorSelected = tempCollabSelected;

      const collaboratorIndex = temp.findIndex(
        (collab: UserType) => collab.email === action.payload.collaboratorSelected.email,
      );

      temp[collaboratorIndex].companiesAffected = temp[collaboratorIndex].companiesAffected.filter(
        (item: CompanyType) => item.slug !== action.payload.company.slug,
      );

      state.collaborators = temp;
    },
    addAffectation: (state, action: PayloadAction<{ collaboratorSelected: UserType; company: CompanyType; }>) => {
      let temp = JSON.parse(JSON.stringify(state.collaborators));
      const collaboratorIndex = temp.findIndex(
        (collab: UserType) => collab.email === action.payload.collaboratorSelected.email,
      );
      temp[collaboratorIndex].companiesAffected.push(action.payload.company);
      state.collaborators = temp;
      let tempCollabSelected = JSON.parse(JSON.stringify(state.collaboratorSelected));

      /// update on selected collaborator
      let copyAffected = tempCollabSelected.companiesAffected;
      copyAffected.push(action.payload.company);
      tempCollabSelected.companiesAffected = copyAffected;
      state.collaboratorSelected = tempCollabSelected;
    },
    updateAccountingFirmPicture: (state, action: PayloadAction<any>) => {
      state.picture = action.payload;
      if (state.accountingFirm !== null) state.accountingFirm = { ...state.accountingFirm, isPictureDefined: true };
    },
  },
});

export const {
  setAccountingFirm,
  setAccountingFirmBillingInformations,
  setCollaborators,
  setConnectors,
  setConnnectorAvailables,
  setConnectorAvailableSelected,
  setConnectorSelected,
  updateConnector,
  updateDefaultConnector,
  createNewConnector,
  addCollaborator,
  updateCollaborator,
  setUserTeams,
  setUserTeamSelected,
  updateUserTeamSelected,
  switchUserBetweenTeams,
  setAccountingFirCompanies,
  ///
  setAccountingFirmPreferences,
  updateAccountingFirmPreferences,
  ///
  setUsersCount,
  setCompaniesCount,
  setCollaboratorSelected,
  setEntriesImportsCount,
  setQuestionsCount,
  deleteCollaborator,
  removeAffectation,
  addAffectation,
  updateAccountingFirmPicture,
  setMustEnterPM,
  setMustEnterPMModal,
} = AccountingFirmSlice.actions;

export default AccountingFirmSlice.reducer;
